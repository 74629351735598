
.temp {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../../assets/images/placeholder.jpg");
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*.c-temp__img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

/*.c-temp__img img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*    z-index: -5;*/
/*    !*object-position: 0 -60px;*!*/
/*}*/

.temp__text {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);

    /*border: 5px solid #333;*/
    z-index: 0;
    /*background-color: rgba(1,0,0,0.4);*/
    padding: 30px 70px;
    /*padding-left: 100px;*/
    color:#EEE;
}

.temp__text__title {
    text-align: center;
}

.temp__text__title__logo {
    width: 250px;
}


.temp__text__contact{
    font-size: 20px;
    text-align: center;
}

.temp__text__contact a {
    text-decoration: none;
    color: inherit;
}

.contact-icon {
    margin-right: 20px
}

@media screen and (max-width: 900px) {
    .temp__text {
        padding: 20px 20px;
        min-width: 320px;
    }
}
