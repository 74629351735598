:root {
    --primary: #000;
    --secondary: #ece7de;
    --background: #fff;
    --primary-font-family: 'League Spartan', sans-serif;
    --primary-font-size: 18px;
    --secondary-font-size: 16px;
    --secondary-font-family: 'Mukta', sans-serif;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: var(--primary-font-family);
}
